import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteBundle, Risk, QuoteState, QuoteRateableState } from '@aventus/platform';
import { ApplicationError } from '@aventus/errors';

export const useNextQuoteIntelligentQuote: UseNextQuoteIntelligentQuote = function (
  risk,
  riskState,
  confirm,
  nextQuoteType,
  quoteState,
  quoteRateableState,
  riskHash,
  goToPreviousPage
) {

  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [ intelligentQuoteBundle, setQuoteBundle ] = React.useState<QuoteBundle | undefined>(undefined);
  const [ isGettingQuoteBundle, setIsGettingQuoteBundle ] = React.useState<boolean>(false);
  const [rateError, setRateError] = React.useState<any>();

  React.useEffect(() => {
    (async function () {

      if (risk && riskState && quoteRateableState === 'Rateable' && !isGettingQuoteBundle) {

        setIsGettingQuoteBundle(true);

        try {

          let _intelligentQuoteBundle;

          switch (nextQuoteType) {

            case 'new':
              _intelligentQuoteBundle  = await symphony.nextQuoteIntelligentQuoteForNew(
                risk,
                riskState,
                confirm
              );
              break;

            case 'adjust':
              _intelligentQuoteBundle  = await symphony.nextQuoteIntelligentQuoteForAdjust(
                risk,
                riskState,
                confirm
              );
              break;

            case 'renew':
              _intelligentQuoteBundle  = await symphony.nextQuoteIntelligentQuoteForRenew(
                risk,
                riskState,
                confirm
              );
              break;

            default:
              throwError(
                new ApplicationError(
                  'The `nextQuoteType` argument was not recognised.'
                )
              );
          }

          setQuoteBundle(_intelligentQuoteBundle);

        } catch (error) {
          goToPreviousPage();
          setRateError(error);
        }

        setIsGettingQuoteBundle(false);

      }

    }());
  }, [ quoteState, quoteRateableState, riskHash ]);

  return {
    intelligentQuoteBundle,
    isGettingQuoteBundle,
    rateError
  };

}

export type UseNextQuoteIntelligentQuote = (
  risk: Risk,
  riskState: string,
  confirm: boolean,
  nextQuoteType: NextQuoteType,
  quoteState: QuoteState,
  quoteRateableState: QuoteRateableState,
  riskHash: string,
  goToPreviousPage: VoidFunction
) => {
  intelligentQuoteBundle: QuoteBundle | undefined;
  isGettingQuoteBundle: boolean,
  rateError: any
}

export type NextQuoteType =
  'new' |
  'adjust' |
  'renew';
